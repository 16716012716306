import React from "react"
import Layout from '../components/layout'
import { Container, Header, Grid} from 'semantic-ui-react';

const ContactPage = () =>{
    return(
        <Layout page="internal">
            <Container fluid>
                <Header as="h1">Design Philosophy</Header>

                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column>
                        <p><strong>Avoid waste through effective design processes</strong><br />
                        With increased popularity of user experience design, implementing an effective process seems to be the obvious. This is not always the case. Design can sometimes be treated as a passive activity within some organizations with little effort allocated to adopting a proven process for the product team. Many projects have time, scope, and cost constraints and lack of a proven, particularly a user-centered, can lead to exceeding the estimated time for design and development. A process such as Lean UX is known to be cost-effective by saving designers and developers time through assumptions validation.  Other processes such as user experience design and design thinking save designers from delivering a product that are not viable. For a team to be effective a proven process is required to reduce design and development time. 
                        </p>
 
                        <p><strong>Frequent evaluation is essential to a better design</strong><br />
                        Instead of conducting only summative evaluation, testing when a product or feature is complete, conducting formative evaluation on designs can improve the results. Although formative evaluation may lead to further iterations, adding quantitative method can determine when to iterate or when to proceed with the design. Usually, formative evaluation is conduct using qualitative methods such as the feedback capture grid. Including quantitative method, based on user experience design heuristics will allow the designers to set a threshold. A threshold established by the team can indicate when a design meet users’ expectation or needs iteration. Conducting frequent evaluation helps with improving design solutions, and when there is uncertainty add a quantitative method. 
                        </p>

                        <p><strong>The aim of product design is to create a product that is marketable. </strong>
                        Product managers are constantly gauging competitors and assessing wither a product has a market fit. A product filled with feature, has a great user experience, and not be marketable. This is like the phenomena of elaborate design of marketing materials which looks aesthetically appealing but does not effectively communicate the marketing message leading to low conversion.  
                         </p>

                       

                        </Grid.Column>
                        <Grid.Column>

                        </Grid.Column>
                    </Grid.Row>

                </Grid>
            </Container>
        </Layout>
    ) 
}

export default ContactPage